import {remoteRefreshClass, remoteRefreshContent} from './remote'

// Send a message
const sendMessage = () => {
    if ($('#chat-input').val() === '') {
        return;
    }

    const form = $('#chat-input').parents('form');

    form.append(`<input type='hidden' name='authenticity_token_' value='${form.find('input[name="authenticity_token"]').val()}'>`);
    form.find("input[type='submit']").click();
    form.find("input[name='authenticity_token_']").remove();
    $('#chat-input').val('');

    // This refresh is not technical required since we'll get an update notification from the websocket anyway but it helps avoid the delay
    remoteRefreshContent("#chat-messages-container");
}

const newMsgSound = new Audio('/chat/new_message.mp3');

// Handle cycling the browser title when the chat is in the background so that new messages are more noticeable
let titleCycleInterval;
const cycleTitle = () => {
    window.clearInterval(titleCycleInterval);

    if (document.__originalTitle === undefined) {
        document.__originalTitle = document.title;
    }

    const titles = $('#chat-unread-data > p').map(function() {
        return $(this).text();
    }).get();

    titles.unshift(document.__originalTitle);
    document.title = titles[0];

    if (document.visibilityState !== 'hidden' || titles.length === 0) {
        return;
    }

    let index = 0;

    titleCycleInterval = window.setInterval(() => {
        document.title = titles[index];
        index += 1;
        if (index === titles.length) {
            index = 0;
        }
    }, 2000);
}

const isMobileDevice = () => {
    return /Mobi|Android|iPhone|iPad|iPod|Windows Phone/i.test(navigator.userAgent);
}

const scrollToBottom = () => {
    const container = document.getElementById('chat-messages-container');

    if (!container) {
        return;
    }

    container.scrollTo({
        top: container.scrollHeight
    });

    // Required for mobile where you're scrolling the whole document (to make sure the browser address bar disappears)
    $('html')[0].scrollTo({top: $('html').innerHeight()});
}

$(document).on("turbolinks:load", (event) => {
    console.log("Chat load");

    if (!isMobileDevice()) { // don't focus input by default on phones because it pops up the onscreen keyboard immediately, obscuring the messages
        $('#chat-input').focus();
    }

    $('#chat-input').keyup((event) => {
        if (event.keyCode === 13 && !event.shiftKey) {
            sendMessage();
        } else {
            // Handle dynamic sizing of the message input field to accomodate multi-line messages (Shift-Enter)
            const lineCount = $('#chat-input').val().split('\n').length;
            $('#chat-input').css({height: `${lineCount * 18}px`});
            $('#chat-input-outer').css({height: `${(lineCount * 18) + 16}px`});
            $('#chat-messages-container').css({height: `calc(100% - ${(lineCount * 18) + 30}px)`});
        }
    });

    scrollToBottom();

    // Scroll to bottom whenever the messages are updated
    $(document).on('remote:load', '#chat-messages-container', (event) => {
        scrollToBottom();
    });
});

// Dynamically handle content appearing/disappearing in the rightmost column
$(document).on("chat-content:load", (event) => {
    console.log("Chat content load", event);
    if ($("#chat-content-container").children().length === 0) {
        $('#chat-columns-container').removeClass('chat-columns-with-content');
    } else {
        $('#chat-columns-container').addClass('chat-columns-with-content');
    }
});

// Handle clicking on suggestions
$(document).on('click', '.chat-suggestion', (event) => {
    const suggestion = $(event.currentTarget).text();
    $('#chat-input').val(suggestion);
    sendMessage();
});

// Send button (visible on mobile)
$(document).on('click', '#chat-input-send-button', (event) => {
    sendMessage();
});

// Content expand/contract content (visible on mobile)
$(document).on('click', '#chat-content-sizer-more', (event) => {
    const container = $('#chat-columns-container');

    if (container.hasClass('chat-content-tall')) {
        container.removeClass('chat-content-tall');
        $('#chat-content-sizer-less').removeClass('chat-content-sizer-disabled');
    } else {
        container.addClass('chat-content-short');
        $('#chat-content-sizer-more').addClass('chat-content-sizer-disabled');
    }
});

$(document).on('click', '#chat-content-sizer-less', (event) => {
    const container = $('#chat-columns-container');

    if (container.hasClass('chat-content-short')) {
        container.removeClass('chat-content-short');
        $('#chat-content-sizer-more').removeClass('chat-content-sizer-disabled');
    } else {
        container.addClass('chat-content-tall');
        $('#chat-content-sizer-less').addClass('chat-content-sizer-disabled');
    }
});

// Unread count changed - play a sound if in background
$(document).on('chatunreaddata:load', (event) => {
    if (document.visibilityState === 'hidden') {
        newMsgSound.play();
        cycleTitle();
    }
});

// Cycle the tab/window title when in background
$(document).on('visibilitychange', (event) => {
    cycleTitle();
});

// Handle feedback in the 3-dots menu on a message
$(document).on('click', '.chat-message-footer-action-bad-response', (event) => {
    const message = $(event.currentTarget).parents('.chat-message');
    const messageId = message.attr('data-message-id');
    const footer = $(event.currentTarget).parents('.chat-message-body-footer');
    $(event.currentTarget).text('Thanks for the feedback');
    footer.slideToggle();
    $.post(`/chat/messages/${messageId}/feedback`, {user_feedback: 'bad'});
});